<template>
    <styled-interface>
        <template #interface-toolbar-external>
            <agency-toolbar-global />
        </template>

        <template #interface-heading>
            Advertising
        </template>

        <styled-card>
            <template #heading>
                Meta Advertising Offline Events
            </template>
            <template #action-buttons>
                <div class="grey--text">
                    <em>
                        Updated <time-ago :value="now.toDate()" />
                    </em>
                </div>
                <action-button
                    icon="refresh"
                    :loading="showLoader"
                    @click="loadData">
                    refresh
                </action-button>
                <action-button
                    :disabled="loading"
                    icon="download"
                    @click="exportCSV">
                    Export CSV
                </action-button>
            </template>
            <div class="groups-picker">
                <div class="groups-picker__holder">
                    <groups-picker-global :disabled="showLoader" />
                </div>
            </div>
            <progressbar-timed
                v-model="loading"
                class="pa-5"
                :gain="loadingGain"
                :top-end="98"
                @complete="showLoader = false">
                {{ progressMessage }}
            </progressbar-timed>
            <div
                v-if="!showLoader && !groupsLoading"
                class="">
                <custom-table
                    id="facebook-offline-events"
                    :key="tableKey"
                    :items="dealerInsights"
                    :fields="fields"
                    :has-filters="true"
                    :has-totals="true"
                    :slim-cells="true"
                    :loading="loading"
                    :header-margin="145"
                    :rows-per-page="50"
                    :footer-titles-colspan="2"
                    name="facebook-offline-events"
                    min-width="1200px">
                    <template #header-slot>
                        <v-select
                            v-model="eventType"
                            :items="FACEBOOK_OFFLINE_EVENT_TYPES"
                            item-text="name"
                            item-value="key"
                            label="Event Type"
                            class="styled-field mr-2"
                            return-object />
                    </template>
                </custom-table>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import { get } from 'lodash';
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import AgencyToolbarGlobal from '@/components/globals/AgencyToolbarGlobal';
import ActionButton from '@/components/globals/ActionButton';
import CustomTable from '@/components/globals/CustomTable/index.vue';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import ProgressbarTimed from '@/components/globals/ProgressbarTimed';
import TimeAgo from '@/components/globals/TimeAgo';
import { FACEBOOK_OFFLINE_EVENT_TYPES, FACEBOOK_ATTRIBUTION_WINDOWS } from '@/helpers/globals';
import { ExportToCsv } from 'export-to-csv';
import getAgencyInsights from '@/apis/facebook/getAgencyInsights';
import formatInsightsData from '@/apis/facebook/formatInsightsData';
import GroupsPickerGlobal from '@/components/globals/GroupsPickerGlobal';

const attributionWindows = [
    '1d_view',
    '1d_click',
    '7d_view',
    '7d_click',
    '28d_view',
    '28d_click'
];

export default {
    name: 'ResellerFacebookOfflineEvents',
    components: {
        AgencyToolbarGlobal,
        GroupsPickerGlobal,
        ActionButton,
        ProgressbarTimed,
        StyledCard,
        StyledInterface,
        CustomTable,
        TimeAgo,
    },
    data() {
        return {
            FACEBOOK_OFFLINE_EVENT_TYPES,
            FACEBOOK_ATTRIBUTION_WINDOWS,
            eventType: null,
            loading: false,
            showLoader: false,
            loadingAdAccounts: false,
            insights: [],
            adAccounts: [],
            loadingInsights: false,
            progressMessage: '',
            loadingGain: 0.5,
            tableKey: 0,
            defaultFields: [
                {
                    id: 'name',
                    header: 'Dealer Name',
                    value: 'dealer.name',
                    width: '180px',
                    align: 'left',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/account-management/AccountLink'),
                    props: {
                        type: 'dealer'
                    }
                },
                {
                    id: 'ad_account',
                    header: 'Ad Account',
                    value: 'name',
                    width: '270px',
                    align: 'left',
                    sortable: true,
                    type: 'component',
                    component: () => import('@/components/resellers/facebook-advertising/AdAccountLink.vue'),
                },
                {
                    id: 'spend',
                    header: 'Spend',
                    value: 'insights.spend.formatted',
                    width: '100px',
                    align: 'center',
                    sortable: true,
                    type: 'single',
                    total: true,
                    average: true,
                    format: 'currency',
                    tooltip: this.$t('metrics.spend'),
                },
                {
                    id: 'reach',
                    header: 'Reach',
                    value: 'insights.reach.formatted',
                    width: '100px',
                    align: 'center',
                    sortable: true,
                    type: 'single',
                    total: true,
                    average: true,
                    format: 'number',
                    tooltip: this.$t('metrics.reach'),
                },
            ],
            now: null,
        };
    },
    computed: {
        ...mapState({
            agency: (state) => state.agency.currentAgency,
            dateRange: (state) => state.metrics.dateRange,
            selectedGroups: (state) => state.agency.selectedGroups,
            groupsLoading: (state) => state.agency.groupsLoading,
            spendOverrideDisable: (state) => state.metrics.spendOverrideDisable,
            attribution: (state) => state.metrics.facebook.ads.attribution
        }),
        ...mapGetters([
            'userIsAdmin'
        ]),
        selectedGroupsIds() {
            return this.selectedGroups.flatMap(group => {
                return group?.id ? group.id : [];
            });
        },
        fields() {
            const fields = [];
            const total = `offline_${this.eventType.key}`;
            const costPerField = `cost_per_offline_${this.eventType.key}`;

            FACEBOOK_ATTRIBUTION_WINDOWS.forEach(window => {
                const key = `offline_${this.eventType.key}_${window.key}`;
                fields.push({
                    id: key,
                    header: window.name,
                    value: `insights.${key}.formatted`,
                    width: '100px',
                    align: 'center',
                    sortable: true,
                    type: 'single',
                    total: true,
                    average: true,
                    format: 'number',
                },);
            });

            fields.push({
                id: total,
                header: 'Total',
                value: `insights.${total}.formatted`,
                width: '100px',
                align: 'center',
                sortable: true,
                type: 'single',
                total: true,
                average: true,
                format: 'number',
            });

            fields.push({
                id: costPerField,
                header: 'Cost Per',
                value: `insights.${costPerField}.formatted`,
                width: '100px',
                align: 'center',
                sortable: true,
                type: 'single',
                total: true,
                average: true,
                format: 'currency',
            });

            return [
                ...this.defaultFields,
                ...fields
            ];
        },
        dealerInsights() {
            return this.insights.map(account => {
                const newAccount = { ...account };

                const dealer = this.dealers.find(dealer => dealer.id == account.dealer_id);

                const spendOverride = dealer.configuration?.facebook_ad_spend_override || null;

                newAccount.insights = formatInsightsData(account.insights, {
                    spendOverride: this.spendOverrideDisable ? null : spendOverride,
                    attribution: this.attribution,
                    attributionWindows
                });

                newAccount.dealer = dealer;
                return newAccount;
            });
        }
    },
    watch: {
        selectedGroups() {
            this.loadData();
        },
        dateRange() {
            this.loadData();
        },
        eventType() {
            this.tableKey += 1;
        }
    },
    async created() {
        // Default to purchase events
        this.eventType = FACEBOOK_OFFLINE_EVENT_TYPES.find(type => type.key === 'purchase');

        this.now = moment();

        this.setTitle();
        this.loadData();
    },
    methods: {
        setTitle() {
            this.$title = `Meta Advertising - ${this.agency.name}`;
        },
        openAdAccount(adAccount) {
            const url = `https://business.facebook.com/adsmanager/manage/campaigns?act=${adAccount}&business_id=${this.agency.facebook_business_id}`;
            window.open(url);
        },
        openAdAccountSettings(adAccount) {
            const url = `https://business.facebook.com/settings/ad-accounts/${adAccount}?business_id=${this.agency.facebook_business_id}`;
            window.open(url);
        },
        goToDealerStats(dealerId) {
            this.$router.push({
                name: 'reports-facebook-advertising',
                params: {
                    dealer_id: dealerId
                }
            });
        },
        async loadData() {
            this.loading = true;
            this.showLoader = true;
            this.loadingGain = 0.3;


            // Ensure calculations are fresh before data is loaded
            this.now = moment();

            this.progressMessage = 'Loading ad accounts...';
            await this.getDealerAdAccounts();

            const count = this.adAccounts.length;
            this.loadingGain = 0.5 - (count * 0.0002);

            this.progressMessage = 'Loading Meta insights...';
            await this.getAdAccountInsights();

            this.progressMessage = 'Loading operation complete!';
            this.loading = false;
        },
        async getDealerAdAccounts() {

            try {
                this.loadingAdAccounts = true;

                const response = await this.$http.get('/dealers', {
                    with_relationships: 'dealers.facebook_ad_account_annotations',
                    with_scopes: {
                        'dealers.agency_equals': {
                            id: this.agency.id
                        },
                        'dealers.groups_in': {
                            ids: this.selectedGroupsIds
                        }
                    },
                    page_size: 1000
                });

                this.dealers = response.data.data;

                const adAccounts = [];

                this.dealers.forEach(dealer => {
                    const adAccount = get(dealer, 'facebook_ad_account_annotations.data[0].facebook_ad_account_id', null);
                    if (adAccount) {
                        adAccounts.push(adAccount);
                    }
                });

                this.adAccounts = adAccounts;
            } catch (error) {
                console.log('Error retreiving dealer ad accounts', error);
            } finally {
                this.loadingAdAccounts = false;
            }
        },
        async getAdAccountInsights() {

            if (!this.adAccounts.length) {
                return;
            }

            try {

                this.loadingInsights = true;

                const fields = [
                    'account_id',
                    'campaign_id',
                    'campaign_name',
                    'account_name',
                    'spend',
                    'reach',
                    'actions'
                ];

                this.insights = await getAgencyInsights({
                    accountIds: this.adAccounts,
                    agencyId: this.agency.id,
                    startDate: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
                    endDate: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
                    campaignFiltering: [{
                        field: 'effective_status',
                        operator: 'IN',
                        value: ['ACTIVE']
                    }],
                    attributionWindows,
                    limit: 150,
                    fields
                });


            } catch (error) {
                console.error('Error retrieving dealer insights', error);
            } finally {
                this.loadingInsights = false;
            }
        },
        exportCSV() {
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: `facebook_offline_events_${new Date().getTime()}`
            };
            const csvExporter = new ExportToCsv(options);
            const result = [];
            const keys = this.fields.map(d => d.id).splice(2);
            this.dealerInsights.map(dealer => {
                const obj = {
                    dealer: dealer.dealer_name,
                    ad_account: dealer.name
                };
                keys.map(k => {
                    obj[k] = dealer.insights[k].formatted;
                });
                result.push(obj);
            });
            csvExporter.generateCsv(result);
        }
    }
};
</script>

<style lang="scss" scoped>
.groups-picker {
    border-bottom: 1px solid $gray-light;
    padding: 15px 40px;
    &__holder {
        max-width: 255px;
    }
}
</style>
<style>
#facebook-offline-events .table-cell:first-of-type{
    background: rgba(143, 158, 166, 0.1);
}
</style>
